import React from "react";
import { FormGroup } from "reactstrap";
import { ChangeCounterButton, Input } from "../../ProductDetail/styles";

const CartItemQuantityForm = ({ quantity, handleQuantity }) => {
  const changeQuantity = (num: number) => {
    const qty = parseInt(quantity, 10);
    const newQuantity = qty + num >= 1 ? qty + num : 1;
    handleQuantity({ target: { value: newQuantity } });
  };
  return (
    <FormGroup className="d-flex mb-0">
      <ChangeCounterButton hideRightBorder onClick={() => changeQuantity(-1)}>
        -
      </ChangeCounterButton>
      <Input
        type="text"
        name="quantity"
        id="cartItem"
        value={quantity}
        readOnly
      />
      <ChangeCounterButton hideLeftBorder onClick={() => changeQuantity(1)}>
        +
      </ChangeCounterButton>
    </FormGroup>
  );
};

export default CartItemQuantityForm;
